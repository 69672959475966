
<div class="login_mainwrapper">
  <div class="login_navbar"><app-frontend-header></app-frontend-header></div>
  <div class="login_main_sub">
  <div class="login_subwrapper">
    <div class="login_formwrapper">
      <div class="login-logowrapper">
        <img
          src="https://d37pbainmkhx6o.cloudfront.net/Timothy-Desmond-Nest/Home/Block2/timothy_circle_logo.webp"
        />
      </div>

        <form
          class="login_form"
          autocomplete="off"
          name="loginForm"
          [formGroup]="loginForm"
        >
          <div class="email_cls">
            <div class="iconwrapper">
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </div>
            <mat-form-field class="login-full-width">
              <input
                matInput
                placeholder="Email"
                #email
                name="email"
                formControlName="email"
                required
                class="form_wrp-field"
                autocomplete="off"
                required
                (keydown)="handleInputChange($event)"
              />
            </mat-form-field>
            <mat-error *ngIf="errors?.email">Email is required</mat-error>
          </div>
          

          <div class="pass_cls">
            <mat-form-field class="login-full-width">
              <input
                matInput
                #password
                [type]="hide ? 'password' : 'text'"
                type="password"
                placeholder="Password"
                name="password"
                formControlName="password"
                required
                class="form_wrp-field"
                autocomplete="off"
                required
                (keydown)="handleInputChange($event)"
              />

              <button
                mat-icon-button
                matSuffix
                type="button"
                (click)="handlevisibl($event)"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide"
              >
                <mat-icon>{{
                  hide ? "visibility_off" : "visibility"
                }}</mat-icon>
              </button>
            </mat-form-field>
            <mat-error *ngIf="errors?.password">Password is required</mat-error>
          </div>
          

            <a [routerLink]="['/forgot-password']">Forgot Password?</a>
        </form>
        <button
          [ngClass]="loginButtonStatus === true ? 'submitdisable' : 'submit'"
          type="submit"
          (click)="login()"
        >
          Login
        </button>
        <mat-progress-bar
          *ngIf="loader"
          mode="indeterminate"
          class="loader_wrp"
        ></mat-progress-bar>
      </div>
    </div>
  </div>
  </div>

<!-- [disabled]="loginButtonStatus?true:false"x -->
<!-- *ngClass="loginButtonStatus? 'submitdisable': 'submit'" -->
