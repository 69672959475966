<div class="top_header">
    <div class="container">
        <div class="top_header_subwrapper">
            <div class="top_header_logowrapper">
            <img  src="https://d37pbainmkhx6o.cloudfront.net/Timothy-Desmond-Nest/Home/timothy_header_block/Timothy_desmond_header_main_logo.webp">
            </div>
            <div class="top_header_textwrapper">
            <!-- <div class="top_header_lefticon backtohome"><i class="fa fa-home" aria-hidden="true"></i>
            <span><section></section> Back to Home page</span>
            </div> -->

            <!-- <div class="top_header_lefticon"><i class="fa fa-user-circle-o" aria-hidden="true"></i></div>
            <p id="header_user_name_section"><span class="skeleton-box" style="width:100px;"></span></p> -->
            <div class="right-menu">
                <div class="top-btn homeIcon" matTooltip="Back to Home Page">
                  <a [href]="frontendurl"><i class="fa fa-home" aria-hidden="true"></i> </a>
                  
                </div>
                <div class="top-btn user">
                  <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                </div>
                <h3 style="text-transform: capitalize;">{{user_profile_details?.username ? user_profile_details?.username : ''}}</h3>
                <div class="top-btn" [matMenuTriggerFor]="rightdownmenu">
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </div>
            
                <mat-menu #rightdownmenu="matMenu">
                  <button mat-menu-item (click)="myAccount()">
                    <i class="fa fa-user-circle-o" aria-hidden="true"></i> My Account
                  </button>
                  <button mat-menu-item (click)="changePass()">
                    <i class="fa fa-unlock-alt" aria-hidden="true"></i> Change Password
                  </button>
                  <!-- <button
                    mat-menu-item
                    (click)="
                      navigateToPath(
                        'astrologer-dashboard/profile/edit/' + cookieData.userinfo._id
                      )
                    "
                  >
                    <i class="fa fa-unlock-alt" aria-hidden="true"></i> View Profile
                  </button> -->
                  <button mat-menu-item (click)="logout()">
                    <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
                  </button>
                </mat-menu>
              </div>
            </div>
        </div>
    </div>
</div>

<div class="BackendHeaderLink" id="affiliateNa">
    <label for="show-menu" class="show-menu"><span><i class="fa fa-bars" aria-hidden="true"></i></span> Menu</label>
    <input type="checkbox" id="show-menu" role="button">
    <ul id="menu">
     <li><a   
        (click)="navigateToPath('/affiliate-dashboard')"
        [ngClass]="
        currentPath() == '/affiliate-dashboard'
          ? 'active'
          : '' " 
        >Dashboard</a></li> 
        <li><a   
          (click)="navigateToPath('/affiliate-dashboard/user')"
          [ngClass]="
          currentPath() == '/affiliate-dashboard/user'
            ? 'active'
            : '' " 
          >Users</a></li> 
          <li><a  
            [matMenuTriggerFor]="Report" 
            [ngClass]=" currentPath() == '/affiliate-dashboard/report/conversion-report'||currentPath() == '/affiliate-dashboard/report/click-report' ? 'active':''"
            >Report <i class="fa-solid fa-circle-chevron-down"></i></a></li> 
            <mat-menu #Report="matMenu">
              <button
                mat-menu-item
                (click)="conversionReportClick()"
               
              >
                Conversion Report
              </button>
              <button
                mat-menu-item
                (click)="clickReportClick()"
                
              >
                Click Report
              </button>
            </mat-menu>
      
      <!-- <li><a href="">Commission</a></li>  -->
  
      </ul>
  </div>