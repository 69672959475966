<button
  mat-dialog-close
  class="close-btn-modal"
  mat-raised-button
  matTooltip="Close"
  matTooltipPosition="below"
>
  <mat-icon>close</mat-icon>
</button>
<div class="dialoghead">
  <h1 class="mat-mdc-dialog-title">
    {{
      typeFlag == true
        ? "Click Details View"
        : typeFlag == false
        ? "Conversion Details View"
        : "Preview"
    }}
  </h1>
  <mat-dialog-content>
    <ng-container *ngFor="let data of datakey">
      <p class="preview_data">
        <span *ngIf="!routeFlag"
          ><strong
            >{{
              data === "created_on"
                ? "Created On"
                : data === "landing_page_name"
                ? "Landing Page Name"
                : data === "fullname"
                ? "Name"
                : data.replace("_", " ")
            }}:</strong
          ></span
        >
        <span *ngIf="routeFlag == 'affiliate-dashboard/user'"
          ><strong
            >{{
              data === "created_on"
                ? "Joined On"
                : data === "landing_page_name"
                ? "Landing Page Name"
                : data === "fullname"
                ? "Name"
                : data.replace("_", " ")
            }}:</strong
          ></span
        >
        <span *ngIf="routeFlag == 'click-conv-prev' && typeFlag"
          ><strong
            >{{
              data === "created_on"
                ? "Clicked On"
                : data === "landing_page_name"
                ? "Landing Page Name"
                : data.replace("_", " ")
            }}:</strong
          ></span
        >
        <span *ngIf="routeFlag == 'click-conv-prev' && !typeFlag"
          ><strong
            >{{
              data === "created_on"
                ? "Converted On"
                : data === "landing_page_name"
                ? "Landing Page Name"
                : data === "affiliate_name"
                ? "User Name"
                : data === "affiliate_number"
                ? "User Phone No"
                : data === "affiliate_email"
                ? "User Email"
                : data.replace("_", " ")
            }}:</strong
          ></span
        >
        <!-- <span *ngIf="typeFlag"
          ><strong
            >{{
              data === "created_on"
                ? "Clicked On"
                : data === "landing_page_name"
                ? "Landing Page Name"
                : data === "fullname"
                ? "Name"
                : data.replace("_", " ")
            }}:</strong
          ></span
        >
        <span *ngIf="!typeFlag"
          ><strong
            >{{
              data === "created_on"
                ? "Conversed On"
                : data === "landing_page_name"
                ? "Landing Page Name"
                : data === "fullname"
                ? "Name"
                : data.replace("_", " ")
            }}:</strong
          ></span
        > -->

        <span class="preview_value">
          {{
            data == "status"
              ? datavalue[data] === 1
                ? "Active"
                : "Inactive"
              : data === "created_on"
              ? created_on
              : data === "updated_on"
              ? updated_on
              : data === "created_at"
              ? created_at
              : data === "email"
              ? datavalue[data]
              : datavalue[data]
              ? datavalue[data]
              : "N/A"
          }}
        </span>
      </p>
    </ng-container>
  </mat-dialog-content>
</div>
