export const environment = {
  stage: 'dev',
  production: false,
  api_url: "https://dev.timothydesmond.influxiq.com/",
  astrology_api_url: "https://json.astrologyapi.com/v1/",
  username: '625019',
  password: '0cfbc4f3b606854ab036efb3f7dbccc4',
  frontendurl: "https://betoparedes.com/",
  fileuploadbaseurl: "https://d36fwfwo4vnk9h.cloudfront.net/",
  url: 'https://d37pbainmkhx6o.cloudfront.net/'                //////////////////////////divine url///////

};