<div class="dashboard_user_detail">
  <div class="dashboard_user_block1">
    <div class="dashboard_user_blockCon">
      <i class="fa fa-user" aria-hidden="true"></i>
      <strong>Welcome:</strong>&nbsp;<span id="user_name_section"
        >{{userName}}<span *ngIf="showSkeleton" class="skeleton-box" style="width: 200px"></span
      ></span>
    </div>
  </div>
  <div class="dashboard_user_block1" *ngIf="isAffiliateID">
    <div class="dashboard_user_blockCon">
      <i class="fa fa-id-card" aria-hidden="true"></i>
      <strong>Affiliate ID:</strong>&nbsp;<span id="user_name_section"
        >{{affiliateID}}<span *ngIf="showSkeleton" class="skeleton-box" style="width: 200px"></span
      ></span>
    </div>
  </div>
  <div class="dashboard_user_block1">
    <div class="dashboard_user_blockCon">
      <i class="fa fa-sign-in" aria-hidden="true"></i>
      <strong>Last Login:</strong>&nbsp;
      <span id="login_time"
        >{{loginTime}}<span *ngIf="showSkeleton" class="skeleton-box" style="width: 300px"></span
      ></span>
    </div>
  </div>
</div>
